// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/bg-guidance.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".message-page[data-v-137c5b62]{width:100%;height:100vh;text-align:center}.msg-bg[data-v-137c5b62]{position:absolute}.msg-title[data-v-137c5b62]{top:6.666667vw;font-size:8vw}.msg-title[data-v-137c5b62],.subhead[data-v-137c5b62]{position:relative;font-family:UKIJCJK;font-weight:400;color:#fff}.subhead[data-v-137c5b62]{top:5.333333vw;font-size:4.8vw;padding-top:4vw}.btn[data-v-137c5b62]{left:50%;bottom:6.666667vw;transform:translateX(-50%);width:68vw;height:16vw}.btn[data-v-137c5b62],.model[data-v-137c5b62]{position:fixed}.model[data-v-137c5b62]{top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.5);z-index:999}.model .guidance[data-v-137c5b62]{position:absolute;top:2.666667vw;right:1.333333vw;width:64vw;height:27.733333vw;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;background-repeat:no-repeat;background-position:50%}.model .guidance .title[data-v-137c5b62]{width:53.333333vw;height:11.733333vw;font-size:4.8vw;font-family:UKIJCJK;font-weight:400;color:#181818;margin:13.333333vw 0 0 5.333333vw}@media (orientation:landscape){.msg-title[data-v-137c5b62]{top:4.401408vw;font-size:5.28169vw}.subhead[data-v-137c5b62]{top:3.521127vw;font-size:3.169014vw;padding-top:2.640845vw}.btn[data-v-137c5b62]{bottom:4.401408vw;width:44.894366vw;height:10.56338vw}.model .guidance[data-v-137c5b62]{top:1.760563vw;right:.880282vw;width:42.253521vw;height:18.309859vw}.model .guidance .title[data-v-137c5b62]{width:35.211268vw;height:7.746479vw;font-size:3.169014vw;margin:8.802817vw 0 0 3.521127vw}}", ""]);
// Exports
module.exports = exports;
