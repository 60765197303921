






































import { Component, Vue } from 'vue-property-decorator'
import { getJudeBrand } from '@/utils/ua'
import { getPlatform } from '@/utils'
@Component({
  name: 'Message'
})
export default class Message extends Vue {
  // 是否显示引导
  isShow = false

  pla = getPlatform()

  created() {
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['Enter_the_landing_page', 'CLK', {}]
    })
  }

  //跳转链接
  downLoadUrl(url: any) {
    if (this.pla === 'weixin' || this.pla === 'qq') {
      this.isShow = true
    } else {
      this.isShow = false
      window.location.href = 'allo://tel.top/home/callshow?select=2'
      let timer: any = null
      const openTime = Date.now()
      const success = () => {
        console.log('调起APP')
        const { aplus_queue }: any = window
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['external_opening', 'CLK', {}]
        })
        clearInterval(timer)
      }

      timer = setInterval(() => {
        if (document.hidden) {
          console.log('调起APP')
          success()
        } else if (Date.now() - openTime > 2500) {
          clearInterval(timer)
          // 打开下载页
          const a = document.createElement('a')
          a.href = url
          a.click()
        }
      }, 200)
      document.addEventListener('visibilitychange', success)
    }
  }

  // 下载App
  goDownload() {
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['click_the_landing_page', 'CLK', {}]
    })

    const androidLinkurl = process.env.VUE_APP_SMS
    const oppoUrl = 'oppomarket://details?packagename=com.allo.contacts'
    const vivoUrl = 'vivomarket://details?id=com.allo.contacts'

    switch (getJudeBrand()) {
      case 'vivo':
        this.downLoadUrl(vivoUrl)
        break
      case 'oppo':
        this.downLoadUrl(oppoUrl)
        break
      default:
        this.downLoadUrl(androidLinkurl)
        break
    }
  }
}
