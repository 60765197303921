/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-05-07 15:52:07
 * @LastEditors: chenzhi
 * @LastEditTime: 2022-11-23 10:23:25
 */
export const UA = navigator.userAgent
export const ua = navigator.userAgent.toLowerCase()

export function getTakTakVersion() {
  const temp = ua.match(/\btaktak\/(\S*)/)
  if (temp) {
    return temp[1]
  }
}

export function getWebviewFrom() {
  const temp = UA.match(/\bWebviewFrom\/(\S*)/)
  if (temp) {
    return temp[1]
  }
}

export function getScreen() {
  const temp = UA.match(/\bScreen\/(\S*)/)
  if (temp) {
    return temp[1]
  }
}

export function getLanguage() {
  const temp = UA.match(/\bLanguage\/(\S*)/)
  if (temp) {
    return temp[1]
  }
}

export function getJudeBrand() {
  const isHuawei = ua.match(/huawei/i)
  const isHonor = ua.match(/honor/i)
  const isOppo = ua.match(/oppo/i)
  const isOppoR15 = ua.match(/pacm00/i)
  const isVivo = ua.match(/vivo/i)
  const isXiaomi = ua.match(/mi\s/i)
  const isXIAOMI = ua.match(/xiaomi/i)
  const isXiaomi2s = ua.match(/mix\s/i)
  const isRedmi = ua.match(/redmi/i)

  if (isHuawei || isHonor) {
    return 'huawei'
  } else if (isOppo || isOppoR15) {
    return 'oppo'
  } else if (isVivo) {
    return 'vivo'
  } else if (isXiaomi || isRedmi || isXiaomi2s || isXIAOMI) {
    return 'xiaomi'
  } else {
    return 'other'
  }
}
